import { Authority } from 'ufinet-web-functions'

export const pagesAndInputs = [
	{
		pathName: 'bitacora',
		viewPermissions: Authority.bitacoraModuleRead,
		menuPermissions: Authority.getBitacoraPermissions,
		reactQueryKeyName: 'bitacora',
		apiRoutePath: 'Bitacora',
		menuTitle: 'MENU.BITACORA.TITTLE',
		downloadedFileName: 'bitacora.xlsx',
		editModalTittleIntl: 'PAGE.BITACORA.EDITMODAL',
		addModalTittleIntl: 'PAGE.BITACORA.NEWMODAL',
		onlyViewMode: true,
		inputs: [
			{
				columnName: 'fecha',
				columnLabelIntl: 'COL.BITACORA.DATE',
				columnWidth: '300px',
				columnType: 'string',
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'usuario',
				columnLabelIntl: 'COL.BITACORA.USER',
				columnWidth: '200px',
				columnType: 'string',
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'tipo',
				columnLabelIntl: 'COL.BITACORA.TYPE',
				columnWidth: '120px',
				columnType: 'string',
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'tabla',
				columnLabelIntl: 'COL.BITACORA.TABLE',
				columnWidth: '180px',
				columnType: 'string',
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'id',
				columnLabelIntl: 'COL.BITACORA.ID',
				columnWidth: '120px',
				columnType: 'string',
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'datos',
				columnLabelIntl: 'COL.BITACORA.DATA',
				columnWidth: '600px',
				columnType: 'string',
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
		],
	},
	{
		pathName: 'Conexiones',
		viewPermissions: Authority.conexionesModuleRead,
		menuPermissions: Authority.getConexionesPermissions,
		reactQueryKeyName: 'conexiones',
		apiRoutePath: 'Conexiones',
		menuTitle: 'MENU.CONEXIONES.TITTLE',
		downloadedFileName: 'conexiones.xlsx',
		editModalTittleIntl: 'PAGE.CONEXIONES.EDITMODAL',
		addModalTittleIntl: 'PAGE.CONEXIONES.NEWMODAL',
		onlyViewMode: true,
		inputs: [
			{
				columnName: 'oltPort',
				columnLabelIntl: 'COL.CONEXIONES.OLTPORT',
				columnWidth: '180px',
				columnType: 'string',
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'rack',
				columnLabelIntl: 'COL.CONEXIONES.RACK',
				columnWidth: '120px',
				columnType: 'string',
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'subRack',
				columnLabelIntl: 'COL.CONEXIONES.SUBRACK',
				columnWidth: '180px',
				columnType: 'string',
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'patch',
				columnLabelIntl: 'COL.CONEXIONES.PATCH',
				columnWidth: '180px',
				columnType: 'string',
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'odfPort',
				columnLabelIntl: 'COL.CONEXIONES.ODFPORT',
				columnWidth: '180px',
				columnType: 'string',
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'splitterPfp',
				columnLabelIntl: 'COL.CONEXIONES.SPLITTERPFP',
				columnWidth: '190px',
				columnType: 'string',
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'pfs',
				columnLabelIntl: 'COL.CONEXIONES.PFS',
				columnWidth: '180px',
				columnType: 'string',
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'pfsPortIn',
				columnLabelIntl: 'COL.CONEXIONES.PFSPORTIN',
				columnWidth: '180px',
				columnType: 'string',
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'splitterPfs',
				columnLabelIntl: 'COL.CONEXIONES.SPLITTERPFS',
				columnWidth: '180px',
				columnType: 'string',
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'pfsPortOut',
				columnLabelIntl: 'COL.CONEXIONES.PFSPORTOUT',
				columnWidth: '180px',
				columnType: 'string',
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'cto',
				columnLabelIntl: 'COL.CONEXIONES.CTO',
				columnWidth: '180px',
				columnType: 'string',
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'ctoPort',
				columnLabelIntl: 'COL.CONEXIONES.CTOPORT',
				columnWidth: '180px',
				columnType: 'string',
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'status',
				columnLabelIntl: 'COL.CONEXIONES.STATUS',
				columnWidth: '180px',
				columnType: 'string',
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'operatorCode',
				columnLabelIntl: 'COL.CONEXIONES.OPERATORCODE',
				columnWidth: '180px',
				columnType: 'string',
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
				selectableOptions: 'operatorCode',
			},
			{
				columnName: 'serviceId',
				columnLabelIntl: 'COL.CONEXIONES.SERVICEID',
				columnWidth: '180px',
				columnType: 'string',
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
		],
	},
	{
		pathName: 'cto',
		viewPermissions: Authority.ctoModuleRead,
		menuPermissions: Authority.getCTOPermissions,
		menuGroupedBy: 'CTO',
		reactQueryKeyName: 'cto',
		apiRoutePath: 'CTO',
		menuTitle: 'MENU.CTO.TITTLE',
		downloadedFileName: 'cto.xlsx',
		editModalTittleIntl: 'PAGE.CTO.EDITMODAL',
		addModalTittleIntl: 'PAGE.CTO.NEWMODAL',
		inputs: [
			{
				columnName: 'cto',
				columnLabelIntl: 'COL.CTO.CTO',
				columnWidth: '190px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'ctO_MODEL',
				columnLabelIntl: 'COL.CTO.CTOMODEL',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
				selectableOptions: 'ctoModel',
			},
			{
				columnName: 'status',
				columnLabelIntl: 'COL.CTO.STATUS',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
				selectableOptions: 'status',
			},
			{
				columnName: 'type',
				columnLabelIntl: 'COL.CTO.TYPE',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'polygon',
				columnLabelIntl: 'COL.CTO.POLYGON',
				columnWidth: '150px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'ctO_NETWORK_TYPE',
				columnLabelIntl: 'COL.CTO.NETWORKTYPE',
				columnWidth: '150px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'qr',
				columnLabelIntl: 'COL.CTO.QR',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: false,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'nfc',
				columnLabelIntl: 'COL.CTO.NFC',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: false,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'address',
				columnLabelIntl: 'COL.CTO.ADDRESS',
				columnWidth: '240px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'addresS_NUMBER',
				columnLabelIntl: 'COL.CTO.ADDRESSNUMBER',
				columnWidth: '250px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'description',
				columnLabelIntl: 'COL.CTO.DESCRIPTION',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: false,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
		],
	},
	{
		pathName: 'CtoA',
		viewPermissions: Authority.ctoaModuleRead,
		menuPermissions: Authority.getCTOAPermissions,
		menuGroupedBy: 'CTO',
		reactQueryKeyName: 'ctoA',
		apiRoutePath: 'CTOA',
		menuTitle: 'MENU.CTOA.TITTLE',
		downloadedFileName: 'ctoA.xlsx',
		editModalTittleIntl: 'PAGE.CTOA.EDITMODAL',
		addModalTittleIntl: 'PAGE.CTOA.NEWMODAL',
		inputs: [
			{
				columnName: 'ctO_A',
				columnLabelIntl: 'COL.CTOA.CTO',
				columnWidth: '150px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'ctO_MODEL',
				columnLabelIntl: 'COL.CTOA.MODEL',
				columnWidth: '220px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'polygon',
				columnLabelIntl: 'COL.CTOA.POLYGON',
				columnWidth: '150px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'address',
				columnLabelIntl: 'COL.CTOA.ADDRESS',
				columnWidth: '180px',
				columnType: 'string',
				isFieldRequired: false,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'ctO_NETWORK_TYPE',
				columnLabelIntl: 'COL.CTOA.NETWORKTYPE',
				columnWidth: '200px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},

			{
				columnName: 'odF_PORT',
				columnLabelIntl: 'COL.CTOA.ODFPORT',
				columnWidth: '180px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'olT_PORT',
				columnLabelIntl: 'COL.CTOA.OLTPORT',
				columnWidth: '150px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
		],
	},
	{
		pathName: 'CtoB',
		viewPermissions: Authority.ctobModuleRead,
		menuPermissions: Authority.getCTOBPermissions,
		menuGroupedBy: 'CTO',
		apiRoutePath: 'CTOB',
		downloadedFileName: 'ctoB.xlsx',
		menuTitle: 'MENU.CTOB.TITTLE',
		reactQueryKeyName: 'ctoB',
		editModalTittleIntl: 'PAGE.CTOB.EDITMODAL',
		addModalTittleIntl: 'PAGE.CTOB.NEWMODAL',
		inputs: [
			{
				columnName: 'cto_B',
				columnLabelIntl: 'COL.CTOB.CTOB',
				columnWidth: '180px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'cto_A',
				columnLabelIntl: 'COL.CTOB.CTOA',
				columnWidth: '250px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'status',
				columnLabelIntl: 'COL.CTOB.STATUS',
				columnWidth: '150px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'type',
				columnLabelIntl: 'COL.CTOB.TYPE',
				columnWidth: '150px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'address',
				columnLabelIntl: 'COL.CTOB.ADDRESS',
				columnWidth: '150px',
				columnType: 'string',
				isFieldRequired: false,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
		],
	},

	{
		pathName: 'CtoCoverage',
		viewPermissions: Authority.ctoCoverageModuleRead,
		menuPermissions: Authority.getCTOCoveragePermissions,
		menuGroupedBy: 'CTO',
		apiRoutePath: 'CtoCoverage',
		menuTitle: 'MENU.CTOCOVERAGE.TITTLE',
		downloadedFileName: 'ctoCoverage.xlsx',
		reactQueryKeyName: 'ctoCoverage',
		editModalTittleIntl: 'PAGE.CTOCOVERAGE.EDITMODAL',
		addModalTittleIntl: 'PAGE.CTOCOVERAGE.NEWMODAL',
		inputs: [
			{
				columnName: 'ctO_COVERAGE_ID',
				columnLabelIntl: 'COL.CTOCOVERAGE.NOTSHOWED',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: false,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
				isFieldEditDisabled: true,
				dontShowInTableAndModal: true,
			},
			{
				columnName: 'cto',
				columnLabelIntl: 'COL.CTOCOVERAGE.CTO',
				columnWidth: '150px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'coverage',
				columnLabelIntl: 'COL.CTOCOVERAGE.COVERAGE',
				columnWidth: '150px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
		],
	},
	{
		pathName: 'Ctoport',
		viewPermissions: Authority.ctoPortModuleRead,
		menuPermissions: Authority.getCTOPortPermissions,
		menuGroupedBy: 'CTO',
		reactQueryKeyName: 'ctoPort',
		apiRoutePath: 'CTOPORT',
		menuTitle: 'MENU.CTOPORT.TITTLE',
		downloadedFileName: 'ctoPort.xlsx',
		editModalTittleIntl: 'PAGE.CTOPORT.EDITMODAL',
		addModalTittleIntl: 'PAGE.CTOPORT.NEWMODAL',
		inputs: [
			{
				columnName: 'cto_Port',
				columnLabelIntl: 'COL.CTOPORT.CTOPORT',
				columnWidth: '280px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'status',
				columnLabelIntl: 'COL.CTOPORT.STATUS',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
				selectableOptions: 'status',
			},
			{
				columnName: 'cto',
				columnLabelIntl: 'COL.CTOPORT.CTO',
				columnWidth: '190px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'operator_Code',
				columnLabelIntl: 'COL.CTOPORT.OPERATORCODE',
				columnWidth: '200px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
				selectableOptions: 'operatorCode',
			},
			{
				columnName: 'pfs_Port_Out',
				columnLabelIntl: 'COL.CTOPORT.PFSPORTOUT',
				columnWidth: '200px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'service_Id',
				columnLabelIntl: 'COL.CTOPORT.SERVICEID',
				columnWidth: '260px',
				columnType: 'string',
				isFieldRequired: false,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'external_Id',
				columnLabelIntl: 'COL.CTOPORT.EXTERNALID',
				columnWidth: '150px',
				columnType: 'string',
				isFieldRequired: false,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
		],
	},
	{
		pathName: 'CtoPortFixed',
		viewPermissions: Authority.ctoPortFixedModuleRead,
		menuPermissions: Authority.getCTOPortFixedPermissions,
		menuGroupedBy: 'CTO',
		reactQueryKeyName: 'ctoPortFixed',
		apiRoutePath: 'CtoPortFixed',
		menuTitle: 'MENU.CTOPORTFIXED.TITTLE',
		downloadedFileName: 'ctoPortFixed.xlsx',
		editModalTittleIntl: 'PAGE.CTOPORTFIXED.EDITMODAL',
		addModalTittleIntl: 'PAGE.CTOPORTFIXED.NEWMODAL',
		inputs: [
			{
				columnName: 'cto_Port',
				columnLabelIntl: 'COL.CTOPORTFIXED.CTOPORT',
				columnWidth: '250px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'cto',
				columnLabelIntl: 'COL.CTOPORTFIXED.CTO',
				columnWidth: '190px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'status',
				columnLabelIntl: 'COL.CTOPORTFIXED.STATUS',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
				selectableOptions: 'status',
			},

			{
				columnName: 'operator_Code',
				columnLabelIntl: 'COL.CTOPORTFIXED.OPERATORCODE',
				columnWidth: '180px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
				selectableOptions: 'operatorCode',
			},
			{
				columnName: 'odf_Port',
				columnLabelIntl: 'COL.CTOPORTFIXED.ODFPORT',
				columnWidth: '180px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'service_Id',
				columnLabelIntl: 'COL.CTOPORTFIXED.SERVICEID',
				columnWidth: '180px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
		],
	},
	{
		pathName: 'CtoModel',
		viewPermissions: Authority.ctoModelModuleRead,
		menuPermissions: Authority.getCTOModelPermissions,
		menuGroupedBy: 'CTO',
		reactQueryKeyName: 'ctoModel',
		apiRoutePath: 'CTOModel',
		menuTitle: 'MENU.CTOMODEL.TITTLE',
		downloadedFileName: 'ctoModel.xlsx',
		editModalTittleIntl: 'PAGE.CTOMODEL.EDITMODAL',
		addModalTittleIntl: 'PAGE.CTOMODEL.NEWMODAL',

		inputs: [
			{
				columnName: 'ctO_MODEL',
				columnLabelIntl: 'COL.CTOMODEL.NAME',
				columnWidth: '250px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
				selectableOptions: 'physical_network_id`',
			},
			{
				columnName: 'description',
				columnLabelIntl: 'COL.CTOMODEL.DESCRIPTION',
				columnWidth: '190px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
		],
	},
	{
		pathName: 'GponTree',
		viewPermissions: Authority.gponTreeModuleRead,
		menuPermissions: Authority.getGponTreePermissions,
		reactQueryKeyName: 'gpontree',
		apiRoutePath: 'GponTree',
		menuTitle: 'MENU.GPONTREE.TITTLE',
		downloadedFileName: 'gpontree.xlsx',
		editModalTittleIntl: 'PAGE.GPONTREE.EDITMODAL',
		addModalTittleIntl: 'PAGE.GPONTREE.NEWMODAL',
		inputs: [
			{
				columnName: 'id',
				columnLabelIntl: 'COL.CTOCOVERAGE.NOTSHOWED',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: false,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
				dontShowInTableAndModal: true,
			},
			{
				columnName: 'patch',
				columnLabelIntl: 'COL.GPONTREE.PATCH',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'physicalNetworkId',
				columnLabelIntl: 'COL.GPONTREE.PHYSICALNETWORKID',
				columnWidth: '200px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'odfPort',
				columnLabelIntl: 'COL.GPONTREE.ODFPORT',
				columnWidth: '180px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'subRack',
				columnLabelIntl: 'COL.GPONTREE.SUBRACK',
				columnWidth: '180px',
				columnType: 'string',
				isFieldRequired: false,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'oltPort',
				columnLabelIntl: 'COL.GONTREE.OLTPORT',
				columnWidth: '250px',
				columnType: 'string',
				isFieldRequired: false,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'rack',
				columnLabelIntl: 'COL.GPONTRE.RACK',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: false,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'olt',
				columnLabelIntl: 'COL.GPONTREE.OLT',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: false,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'placa',
				columnLabelIntl: 'COL.GPONTREE.PLACA',
				columnWidth: '120px',
				columnType: 'string',
				isFildRequired: false,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
		],
	},
	{
		pathName: 'Operator',
		viewPermissions: Authority.operatorModuleRead,
		menuPermissions: Authority.getOperatorPermissions,
		reactQueryKeyName: 'operator',
		apiRoutePath: 'Operator',
		menuTitle: 'MENU.OPERATOR.TITTLE',
		downloadedFileName: 'operator.xlsx',
		editModalTittleIntl: 'PAGE.OPERATOR.EDITMODAL',
		addModalTittleIntl: 'PAGE.OPERATOR.ADDMODAL',
		inputs: [
			{
				columnName: 'operatorCode',
				columnLabelIntl: 'COL.OPERATOR.CODE',
				columnWidth: '120',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'name',
				columnLabelIntl: 'COL.OPERATOR.NAME',
				columnWidth: '120',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'physicalNetwork',
				columnLabelIntl: 'COL.OPERATOR.PHYSICALNETWORK',
				columnWidth: '120',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
				selectableOptions: 'physicalNetworkForOperator',
			},
		],
	},
	{
		pathName: 'pfs',
		viewPermissions: Authority.pfsModuleRead,
		menuPermissions: Authority.getPFSPermissions,
		menuGroupedBy: 'PFS',
		reactQueryKeyName: 'pfs',
		apiRoutePath: 'Pfs',
		menuTitle: 'MENU.PFS.TITTLE',
		downloadedFileName: 'pfs.xlsx',
		editModalTittleIntl: 'PAGE.PFS.EDITMODAL',
		addModalTittleIntl: 'PAGE.PFS.NEWMODAL',
		inputs: [
			{
				columnName: 'id',
				columnLabelIntl: 'COL.PFS.ID',
				columnWidth: '100',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'pfsModel',
				columnLabelIntl: 'COL.PFS.PFSMODEL',
				columnWidth: '120',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'qr',
				columnLabelIntl: 'COL.PFS.QR',
				columnWidth: '120',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},

			{
				columnName: 'maxPortIn',
				columnLabelIntl: 'COL.PFS.MAXPORTIN',
				columnWidth: '120',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
		],
	},
	{
		pathName: 'PfsModel',
		viewPermissions: Authority.pfsModelModuleRead,
		menuPermissions: Authority.getPFSModelPermissions,
		menuGroupedBy: 'PFS',
		reactQueryKeyName: 'pfsModel',
		apiRoutePath: 'PfsModel',
		menuTitle: 'MENU.PFSMODEL.TITTLE',
		downloadedFileName: 'pfsModel.xlsx',
		editModalTittleIntl: 'PAGE.PFSMODEL.EDITMODAL',
		addModalTittleIntl: 'PAGE.PFSMODEL.NEWMODAL',
		inputs: [
			{
				columnName: 'pfsModelId',
				columnLabelIntl: 'COL.PFSMODEL.ID',
				columnWidth: '190',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'maxPortOut',
				columnLabelIntl: 'COL.PFSMODEL.MAXPORTOUT',
				columnWidth: '190',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'description',
				columnLabelIntl: 'COL.PFSMODEL.DESCRIPTION',
				columnWidth: '190',
				columnType: 'string',
				isFieldRequired: false,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'port',
				columnLabelIntl: 'COL.PFSMODEL.PORT',
				columnWidth: '120',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'coordinate',
				columnLabelIntl: 'COL.PFSMODEL.COORDINATE',
				columnWidth: '120',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'pfsModelCoordenateId',
				columnLabelIntl: 'NOTSHOWED',
				columnWidth: '120',
				columnType: 'number',
				isFieldRequired: false,
				requiredFieldErrorText: 'NOTSHOWED',
				dontShowInTableAndModal: true,
			},
		],
	},
	{
		pathName: 'PfsPortIn',
		viewPermissions: Authority.pfsPortInModuleRead,
		menuPermissions: Authority.getPFSPortInPermissions,
		menuGroupedBy: 'PFS',
		reactQueryKeyName: 'pfsPortIn',
		apiRoutePath: 'PfsPortIn',
		menuTitle: 'MENU.PFSPORTIN.TITTLE',
		downloadedFileName: 'pfsPortIn.xlsx',
		editModalTittleIntl: 'PAGE.PFSPORTIN.EDITMODAL',
		addModalTittleIntl: 'PAGE.PFSPORTIN.NEWMODAL',
		inputs: [
			{
				columnName: 'pfsPortIn',
				columnLabelIntl: 'COL.PFSPORTIN.IN',
				columnWidth: '120',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'status',
				columnLabelIntl: 'COL.PFSPORTIN.STATUS',
				columnWidth: '120',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
				selectableOptions: 'status',
			},
			{
				columnName: 'pfs',
				columnLabelIntl: 'COL.PFSPORTIN.PFS',
				columnWidth: '120',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},

			{
				columnName: 'odfPort',
				columnLabelIntl: 'COL.PFSPORTIN.ODFPORT',
				columnWidth: '120',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
		],
	},
	{
		pathName: 'PfsPortOut',
		viewPermissions: Authority.pfsPortInModuleRead,
		menuPermissions: Authority.getPFSPortOutPermissions,
		menuGroupedBy: 'PFS',
		reactQueryKeyName: 'pfsPortOut',
		apiRoutePath: 'PfsPortOut',
		menuTitle: 'MENU.PFSPORTOUT.TITTLE',
		downloadedFileName: 'pfsPortOut.xlsx',
		editModalTittleIntl: 'PAGE.PFSPORTOUT.EDITMODAL',
		addModalTittleIntl: 'PAGE.PFSPORTOUT.NEWMODAL',
		inputs: [
			{
				columnName: 'pfsPortOut',
				columnLabelIntl: 'COL.PFSPORTOUT.OUT',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'pfs',
				columnLabelIntl: 'COL.PFSPORTOUT.PFS',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'splitterPfs',
				columnLabelIntl: 'COL.PFSPORTOUT.SPLITTERPFS',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
		],
	},
	{
		pathName: 'PhysicalNetwork',
		viewPermissions: Authority.physicalNetworkModuleRead,
		menuPermissions: Authority.getPhysicalNetworkPermissions,
		reactQueryKeyName: 'physicalNetwork',
		apiRoutePath: 'PhysicalNetwork',
		menuTitle: 'MENU.PHYSICALNETWORK.TITTLE',
		downloadedFileName: 'physicalNetwork.xlsx',
		editModalTittleIntl: 'PAGE.PHYSICALNETWORK.EDITMODAL',
		addModalTittleIntl: 'PAGE.PHYSICALNETWORK.ADDMODAL',
		inputs: [
			{
				columnName: 'physicalNetworkId',
				columnLabelIntl: 'COL.PHYSICALNETWORK.ID',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'co',
				columnLabelIntl: 'COL.PYSICALNETWORK.CO',
				columnWidth: '190px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'odn',
				columnLabelIntl: 'COL.PHYSICALNETWORK.ODN',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
		],
	},
	{
		pathName: 'polygon',
		viewPermissions: Authority.polygonModuleRead,
		menuPermissions: Authority.getPolygonPermissions,
		menuGroupedBy: 'POLYGON',
		reactQueryKeyName: 'polygon',
		apiRoutePath: 'polygon',
		menuTitle: 'MENU.POLYGON.TITTLE',
		downloadedFileName: 'polygon.xlsx',
		editModalTittleIntl: 'PAGE.POLYGON.EDITMODAL',
		addModalTittleIntl: 'PAGE.POLYGON.NEWMODAL',
		inputs: [
			{
				columnName: 'polygon',
				columnLabelIntl: 'COL.POLYGON.POLYGON',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'description',
				columnLabelIntl: 'COL.POLYGON.DESCRIPTION',
				columnWidth: '190px',
				columnType: 'string',
				isFieldRequired: false,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'type',
				columnLabelIntl: 'COL.POLYGON.TYPE',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
				selectableOptions: 'polygonType',
			},
			{
				columnName: 'totaL_HP',
				columnLabelIntl: 'COL.POLYGON.TOTALHP',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'prioritization_Type',
				columnLabelIntl: 'COL.POLYGON.PRIORITIZATIONTYPE',
				columnWidth: '200px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
		],
	},
	{
		pathName: 'PolygonCoverage',
		viewPermissions: Authority.polygonCoverageModuleRead,
		menuPermissions: Authority.getPolygonCoveragePermissions,
		menuGroupedBy: 'POLYGON',
		reactQueryKeyName: 'polygonCoverage',
		apiRoutePath: 'PolygonCoverage',
		menuTitle: 'MENU.POLYGONCOVEREGE.TITTLE',
		downloadedFileName: 'polygonCoverage.xlsx',
		editModalTittleIntl: 'PAGE.POLYGONCOVERAGE.EDITMODAL',
		addModalTittleIntl: 'PAGE.POLYGONCOVERAGE.NEWMODAL',
		inputs: [
			{
				columnName: 'polygon_Coverage_Id',
				columnLabelIntl: 'COL.CTOCOVERAGE.NOTSHOWED',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: false,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
				dontShowInTableAndModal: true,
			},
			{
				columnName: 'polygon',
				columnLabelIntl: 'COL.POLYGONCOVERAGE.POLYGON',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'operator_Code',
				columnLabelIntl: 'COL.POLYGONCOVERAGE.OPERATORCODE',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
				selectableOptions: 'operatorCode',
			},
			{
				columnName: 'polygon_Alias',
				columnLabelIntl: 'COL.POLYGONCOVERAGE.ALIAS',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
		],
	},
	{
		pathName: 'PolygonOptimization',
		viewPermissions: Authority.polygonOptimizationModuleRead,
		menuPermissions: Authority.getPolygonOptimizationPermissions,
		menuGroupedBy: 'POLYGON',
		reactQueryKeyName: 'polygonOptimization',
		apiRoutePath: 'PolygonOptimization',
		menuTitle: 'MENU.POLYGONOPTIMIZATION.TITTLE',
		downloadedFileName: 'polygonOptimization.xlsx',
		editModalTittleIntl: 'PAGE.POLYGONOPTIMIZATION.EDITMODAL',
		addModalTittleIntl: 'PAGE.POLYGONOPTIMIZATION.NEWMODAL',
		inputs: [
			{
				columnName: 'polygon_Id',
				columnLabelIntl: 'COL.POLYGONOPTIMIZATION.POLYGON',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'cto',
				columnLabelIntl: 'COL.POLYGONOPTIMIZATION.CTO',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
		],
	},
	{
		pathName: 'ResourceCharacteristic',
		viewPermissions: Authority.resourceRelationshipModuleRead,
		menuPermissions: Authority.getResourcesCharacteristicsPermissions,
		reactQueryKeyName: 'resourceCharacteristic',
		apiRoutePath: 'ResourceCharacteristic',
		menuTitle: 'MENU.RESOURCECHARACTERISTICS.TITTLE',
		downloadedFileName: 'resourceCharacteristic.xlsx',
		editModalTittleIntl: 'PAGE.RESOURCECHARACTERISTIC.EDITMODAL',
		addModalTittleIntl: 'PAGE.RESOURCECHARACTERISTIC.NEWMODAL',
		inputs: [
			{
				columnName: 'resourceId',
				columnLabelIntl: 'COL.RESOURCECHARACTERISTIC.ID',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'name',
				columnLabelIntl: 'COL.RESOURCECHARACTERISTIC.NAME',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'value',
				columnLabelIntl: 'COL.RESOURCECHARACTERISTIC.VALUE',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
		],
	},
	{
		pathName: 'ResourceRelationship',
		viewPermissions: Authority.resourceRelationshipModuleRead,
		menuPermissions: Authority.getResourceRelationshipPermissions,
		reactQueryKeyName: 'resourceRelationship',
		apiRoutePath: 'ResourceRelationship',
		menuTitle: 'MENU.RESOURCERELATIONSHIP.TITTLE',
		downloadedFileName: 'resourceRelationship.xlsx',
		editModalTittleIntl: 'PAGE.RESOURCERELATIONSHIP.EDITMODAL',
		addModalTittleIntl: 'PAGE.RESOURCERELATIONSHIP.NEWMODAL',
		inputs: [
			{
				columnName: 'resource_Id',
				columnLabelIntl: 'COL.RESOURCERELATIONSHIP.RESOURCEID',
				columnWidth: '190px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'resource_Related',
				columnLabelIntl: 'COL.RESOURCERELATIONSHIP.RESOURCERELATED',
				columnWidth: '190px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'relation_Type',
				columnLabelIntl: 'COL.RESOURCERELATIONSHIP.RELATIONTYPE',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'role',
				columnLabelIntl: 'COL.RESOURCERELATIONSHIP.ROLE',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
		],
	},
	{
		pathName: 'Resources',
		viewPermissions: Authority.resourcesModuleRead,
		menuPermissions: Authority.getResourcesPermissions,
		reactQueryKeyName: 'resources',
		apiRoutePath: 'Resource',
		menuTitle: 'MENU.RESOURCES.TITTLE',
		downloadedFileName: 'resourcea.xlsx',
		editModalTittleIntl: 'PAGE.RESOURCE.EDITMODAL',
		addModalTittleIntl: 'PAGE.RESOURCE.NEWMODAL',
		inputs: [
			{
				columnName: 'id',
				columnLabelIntl: 'COL.RESOURCE.ID',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'brand',
				columnLabelIntl: 'COL.RESOURCE.BRAND',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'model',
				columnLabelIntl: 'COL.RESOURCE.MODEL',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'deviceType',
				columnLabelIntl: 'COL.RESOURCE.DEVICETYPE',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'serie',
				columnLabelIntl: 'COL.RESOURCE.SERIE',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'state',
				columnLabelIntl: 'COL.RESOURCE.STATE',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
		],
	},
	{
		pathName: 'Services',
		viewPermissions: Authority.servicesFtthModuleRead,
		menuPermissions: Authority.getServicesFtthPermissions,
		reactQueryKeyName: 'services',
		apiRoutePath: 'Servicios',
		menuTitle: 'MENU.SERVICES.TITTLE',
		downloadedFileName: 'services.xlsx',
		editModalTittleIntl: 'PAGE.SERVICE.EDITMODAL',
		addModalTittleIntl: 'PAGE.SERVICE.NEWMODAL',
		inputs: [
			{
				columnName: 'serviceId',
				columnLabelIntl: 'COL.SERVICE.ID',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'operatorCode',
				columnLabelIntl: 'COL.SERVICE.OPERATORCODE',
				columnWidth: '180px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
				selectableOptions: 'operatorCode',
			},
			{
				columnName: 'ctob',
				columnLabelIntl: 'COL.SERVICE.CTOB',
				columnWidth: '150px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'labelDrop',
				columnLabelIntl: 'COL.SERVICE.LABELDROP',
				columnWidth: '150px',
				columnType: 'string',
				isFieldRequired: false,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'serviceDescription',
				columnLabelIntl: 'COL.SERVICE.SERVICEDESCRIPTION',
				columnWidth: '200px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'status',
				columnLabelIntl: 'COL.SERVICE.STATUS',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
				selectableOptions: 'status',
			},
		],
	},
	{
		pathName: 'SplitterPFP',
		viewPermissions: Authority.splitterPfpModuleRead,
		menuPermissions: Authority.getSplitterPfpPermissions,
		reactQueryKeyName: 'splitterPFP',
		apiRoutePath: 'SplitterPFP',
		menuTitle: 'MENU.SPLITTERPFP.TITTLE',
		downloadedFileName: 'splitterPfp.xlsx',
		editModalTittleIntl: 'PAGE.SPLITTERPFP.EDITMODAL',
		addModalTittleIntl: 'PAGE.SPLITTERPFP.NEWMODAL',
		inputs: [
			{
				columnName: 'id',
				columnLabelIntl: 'COL.SPLITTERPFP.ID',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'odfPort',
				columnLabelIntl: 'COL.SPLITTERPFP.ODFPORT',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
		],
	},
	{
		pathName: 'SplitterPFS',
		viewPermissions: Authority.splitterPfsModuleRead,
		menuPermissions: Authority.getSplitterPfsPermissions,
		reactQueryKeyName: 'splitterPFS',
		apiRoutePath: 'splitterPFS',
		menuTitle: 'MENU.SPLITTERPFS.TITTLE',
		downloadedFileName: 'splitterPfs.xlsx',
		editModalTittleIntl: 'PAGE.SPLITTERPFS.EDITMODAL',
		addModalTittleIntl: 'PAGE.SPLITTERPFS.NEWMODAL',
		inputs: [
			{
				columnName: 'id',
				columnLabelIntl: 'COL.SPLITTERPFS.ID',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'pfsPortIn',
				columnLabelIntl: 'COL.SPLITTERPFS.PFSPORTIN',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'failedPorts',
				columnLabelIntl: 'COL.SPLITTERPFS.FAILEDPORTS',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'maxPortsOut',
				columnLabelIntl: 'COL.SPLITTERPFS.MAXPORTOUT',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
		],
	},
	{
		pathName: 'VlanInventory',
		viewPermissions: Authority.vlanInventoryModuleRead,
		menuPermissions: Authority.getVlanInventoryPermissions,
		reactQueryKeyName: 'vlanInventory',
		apiRoutePath: 'VlanInventory',
		menuTitle: 'MENU.VLANINVENTORY.TITTLE',
		downloadedFileName: 'vlanInventory.xlsx',
		editModalTittleIntl: 'PAGE.VLANINVENTORY.EDITMODAL',
		addModalTittleIntl: 'PAGE.VLANINVENTORY.NEWMODAL',
		inputs: [
			{
				columnName: 'operator_Code',
				columnLabelIntl: 'COL.VLANINVENTORY.OPERATORCODE',
				columnWidth: '180px',
				columnType: 'string',
				isFieldRequired: true,
				isFieldEditDisabled: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
				selectableOptions: 'operatorCode',
			},
			{
				columnName: 'olt',
				columnLabelIntl: 'COL.VLANINVENTORY.OLT',
				columnWidth: '190px',
				columnType: 'string',
				isFieldRequired: true,
				isFieldEditDisabled: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'virtual_Olt',
				columnLabelIntl: 'COL.VLANINVENTORY.VIRTUALOLT',
				columnWidth: '190px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'type',
				columnLabelIntl: 'COL.VLANINVENTORY.TYPE',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				isFieldEditDisabled: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'extreme',
				columnLabelIntl: 'COL.VLANINVENTORY.EXTREME',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				isFieldEditDisabled: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'vlan',
				columnLabelIntl: 'COL.VLANINVENTORY.VLAN',
				columnWidth: '150px',
				columnType: 'number',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
		],
	},
	{
		pathName: 'OLTRegistry',
		viewPermissions: Authority.oltRegistryModuleRead,
		menuPermissions: Authority.getOltRegistryPermissions,
		reactQueryKeyName: 'oltRegistry',
		apiRoutePath: 'OLTRegistry',
		menuTitle: 'MENU.OLTREGISTRY.TITTLE',
		downloadedFileName: 'oltRegistry.xlsx',
		editModalTittleIntl: 'PAGE.OLTREGISTRY.EDITMODAL',
		addModalTittleIntl: 'PAGE.OLTREGISTRY.NEWMODAL',
		inputs: [
			{
				columnName: 'olT_REGISTRY',
				columnLabelIntl: 'COL.OLTREGISTRY.OLT_REGISTRY_ID',
				columnWidth: '180px',
				columnType: 'string',
				dontShowInTableAndModal: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'physicaL_NETWORK_ID',
				columnLabelIntl: 'COL.OLTREGISTRY.PHYSICAL_NETWORK_ID',
				columnWidth: '180px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'olt',
				columnLabelIntl: 'COL.OLTREGISTRY.OLT',
				columnWidth: '190px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'olT_PORT',
				columnLabelIntl: 'COL.OLTREGISTRY.OLT_PORT',
				columnWidth: '190px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'suB_RACK',
				columnLabelIntl: 'COL.OLTREGISTRY.SUB_RACK',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'rack',
				columnLabelIntl: 'COL.OLTREGISTRY.RACK',
				columnWidth: '120px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'placa',
				columnLabelIntl: 'COL.OLTREGISTRY.PLACA',
				columnWidth: '150px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
			{
				columnName: 'estado',
				columnLabelIntl: 'COL.OLTREGISTRY.STATUS',
				columnWidth: '150px',
				columnType: 'string',
				isFieldRequired: true,
				requiredFieldErrorText: 'MODALS.REQUIREDERROR',
			},
		],
	},
]
